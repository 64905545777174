<template>
  <div style="background-color: #0a092d;">

    <div style="height: 63px; background-color: #0a092d; border-bottom: thin solid #282e3e"
         class="d-flex align-center pl-4"
    >

      <h1
        @click="$router.push({path: '/'})"
        style="color: white; font-size: 1.6em; letter-spacing: -1px !important; font-weight: 700 !important; cursor: pointer"
        class="mb-1 font-rubik"
      >
        <span>examenselectivitat</span>
      </h1>
    </div>

    <div style="position: absolute; top: 63px; left: 0; width: 100%; z-index: 1 !important;" class="d-flex">
      <v-img src="https://examenselectivitat.cat:3000/api/images/other/headerBg.png"
             max-width="770px"
             class="mx-auto"
      ></v-img>
    </div>

    <div
      v-if="false"
      style="height: 100vh; padding-bottom: 240px; background-color: white"
      class="d-flex flex-column align-center justify-center text-center">
      <v-img src="../../assets/logo.png"
             width="256"
             height="256"
             style="max-height: 300px"
             contain
      ></v-img>
      <h1
        @click="$router.push({path: '/'})"
        style="color: #256EFF; font-size: 3.6em; letter-spacing: -1px !important; font-weight: 700 !important; cursor: pointer"
        class="mb-1 font-rubik"
      >
        <span>examenselectivitat</span>
      </h1>
      <h1
        style="font-weight: 700; font-size: 2.9rem; background-color: #ffb845; z-index: 99 !important;   text-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1) !important;"
        class="font-rubik rounded-lg px-10 white--text mt-5"
      >
        Premium
      </h1>
    </div>

    <div
      style="width: 100%; position: relative; z-index: 99"
    >


      <v-container style="z-index: 99 !important;"
                   class="pt-5 premium-header text-center">
        <div class="d-flex flex-column">
          <h1
            style="font-weight: 700; font-size: 3.375rem; color: white; z-index: 99 !important;"
            class="font-rubik"
          >
            Fes-te prèmium! ✨
          </h1>
          <span
            style="font-size: 1.5rem; line-height: 2rem; color: white; font-weight: 400 !important;"
            class="font-rubik"
          >
          Millora les teves qualificacions practicant sense distraccions.
        </span>
          <span
            style="font-size: 1rem; line-height: 2rem; color: white;"
            class="font-rubik mt-2"
          >
            Ajuda'ns per a poder continuar afegint contingut de qualitat any darrere any.
          </span>
        </div>

      </v-container>


      <v-container
        class="pb-12 pt-6">
        <abonaments class="mx-auto"></abonaments>
      </v-container>


      <div
        style="background-color: white" class="pb-12">
        <v-container>
          <v-row class="mt-12">


            <v-card
              :v-intersect="(entries) => personIntersecting = entries[0].isIntersecting "
              class="d-flex rounded-xl my-12 10"
                    style="max-height: 300px; width: 100%"
                    flat color="#FFA204">

              <div style="position: absolute; left: 40px; top: 0; height: 100%; width: fit-content; z-index: 4"
                   class="d-flex flex-column justify-space-around"
              >
            <span class="font-rubik font-weight-bold white--text px-2 mt-auto"
                  style="font-size: 2rem; background-color: #266EFE; line-height: 1; width: fit-content"
            >
              +3.000
            </span>
                <span class="font-rubik font-weight-bold px-2"
                      style="font-size: 1.5rem; line-height: 1; background-color: #FBA204"
                >
              problemes
            </span>

                <span class="font-rubik font-weight-bold px-2 mb-auto ml-2"
                      style="font-size: 1.5rem; line-height: 1; background-color: #FBA204"
                >
              solucionats
            </span>

              </div>
              <v-spacer></v-spacer>
              <v-img
                style="max-height: 200px; max-width: 352px"
                class="my-auto"
                src="https://examenselectivitat.cat:3000/api/images/other/premium_dec1.png"></v-img>
              <v-img
                style="max-height: 200px; max-width: 102px"
                class="my-auto"
                src="https://examenselectivitat.cat:3000/api/images/other/premium_dec2.png"></v-img>
            </v-card>


            <preguntes-frequents></preguntes-frequents>

          </v-row>


        </v-container>

      </div>
    </div>

    <div
        v-if="false"
        style="background-color: white; padding-top: 65px; padding-bottom: 110px">
      <v-container class="pb-12 rounded-xl"
                   style="background-color: #740AFF; position: relative"
                   :v-intersect="(entries) => starsIntersecting = entries[0].isIntersecting "
      >
        <v-img
          v-if="starsIntersecting"
          class="roll-in-right"
          style="position: absolute; right: -40px; top: -62px; transform: rotate(-3deg)"
          height="256"
          width="256"
          src="https://examenselectivitat.cat:3000/api/images/other/stars.webp"></v-img>

        <div class="text-center mb-12">
          <h2
            style="font-weight: 700; font-size: 2.5rem; color: white; z-index: 99 !important;"
            class="font-rubik"
          >
            No perdis el temps
          </h2>
          <h3
            style="font-weight: 700; font-size: 1.5rem; color: white; z-index: 99 !important;"
            class="font-rubik"
          >
            Fes-te prèmium
          </h3>
        </div>
        <abonaments class="mx-auto"></abonaments>

      </v-container>
    </div>

    <v-sheet color="#F8F9FD" height="150"
             class="d-flex align-center pr-12"
    >
      <v-spacer></v-spacer>
      <v-img src="https://examenselectivitat.cat:3000/api/images/other/stripe-payments.png"
             max-width="400"
             max-height="50"
             contain
      ></v-img>
    </v-sheet>

  </div>
</template>

<script>
import PreguntesFrequents from "./components/PreguntesFrequents.vue";
import Abonaments from "./components/Abonaments.vue";

export default {
  name: "PremiumPage",
  components: {Abonaments, PreguntesFrequents},
  data() {
    return {
      personIntersecting: false,
      starsIntersecting: false,
    }
  },
  computed: {
    nextYear() {
      const d1 = new Date();
      let year = d1.getFullYear();
      let month = d1.getMonth() + 1;
      if (month >= 9) year++;
      return year;
    }
  },
}
</script>

<style scoped>
.animate-bounce {
  animation: bounce 1.2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(-50%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.with-triangule-bottom:after {
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #ffc633;
  transform: translateX(-50%);
  position: absolute;
  z-index: 0;
  content: "";
  top: 100%;
  left: 50%;
  height: 0;
}

.premium-header, .premium-header >>> * {
  z-index: 99 !important;
}


.roll-in-right {
  -webkit-animation: roll-in-right 0.6s ease-out both;
  animation: roll-in-right 0.6s ease-out both;
}


/* ----------------------------------------------
 * Generated by Animista on 2023-4-16 20:26:44
 * Licensed under FreeBSD License.
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation roll-in-right
 * ----------------------------------------
 */
@-webkit-keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@keyframes roll-in-right {
  0% {
    -webkit-transform: translateX(800px) rotate(540deg);
    transform: translateX(800px) rotate(540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

</style>
